import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

export const useGoogleAnalytics = () => {
  const location = useLocation();
  const googleAnalytics = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

  useEffect(() => {
    if (googleAnalytics) {
      ReactGA.initialize(googleAnalytics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (googleAnalytics) {
      const currentPath = location.pathname + location.search;
      ReactGA.set({ page: currentPath });
      ReactGA.pageview(currentPath);
    }
  }, [location, googleAnalytics]);
};
