import { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'components-library/button';
import { Input } from 'components-library/input';
import { HR } from '../styled';
import { Styles } from 'styles';
import { useSendTransaction, useNetwork, useAccount } from 'wagmi';
import { message } from 'components-library/message';
import { Loader } from 'components-library/loader';
import Web3 from 'web3';
import { useBalance } from 'wagmi';
import { isDev } from 'utils/is-dev';
import { useMutation } from '@apollo/client';
import { VALIDATE_PROMO_CODE } from 'utils/gql';

const CheckoutBtnsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const PromoCodeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
`;

const CheckoutLine = styled.div`
  border-bottom: 1px solid ${Styles.color.white}22;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

const RegularPrice = styled.div<{ discount?: boolean }>`
  ${(p) =>
    p.discount &&
    css`
      text-decoration: line-through ${Styles.color.white}66;
    `};
`;

const PromoCode = styled.code`
  border-color: ${Styles.color.primary};
  background: ${Styles.color.primary}44;
  margin-right: 12px;
`;

const STAKING_ADDRESS = '0xbfAe7c5eE7010c464d68db0123C9BA8eba4b4C66';

export const PROD_PRICE_IN_ETHER = 0.018;
export const DEV_PRICE_IN_ETHER = 0.000001;

export const PRICE_IN_ETHER = isDev() ? DEV_PRICE_IN_ETHER : PROD_PRICE_IN_ETHER;

export const PRICE_IN_WEI = Number(Web3.utils.toWei(PRICE_IN_ETHER.toString(), 'ether'));

export const Checkout = ({
  setPageState,
  handleSubmit,
  promotionalCode,
  handleChange,
  addJobMutationIsLoading,
  priceInUsd
}) => {
  const {
    activeChain,
    chains,
    error,
    isLoading: networkIsLoading,
    pendingChainId,
    switchNetwork
  } = useNetwork();

  const [discount, setDiscount] = useState(0);
  const discountValue = PRICE_IN_WEI * (discount / 100);

  const { data: balanceData, isLoading: balanceLoading } = useBalance({
    addressOrName: STAKING_ADDRESS
  });

  const [promoCodeIsLoading, setPromoCodeIsLoading] = useState(false);

  const [validatePromoCodeMutation] = useMutation(VALIDATE_PROMO_CODE);

  const userBalance = Number(balanceData?.formatted);

  const handleApplyPromoCode = useCallback(
    async (e) => {
      e.preventDefault();
      if (!promotionalCode.length) {
        return;
      }
      setPromoCodeIsLoading(true);
      const response = await validatePromoCodeMutation({
        variables: { promoCode: promotionalCode }
      });

      const discountResponse = response?.data?.validatePromoCode?.discount;
      if (discountResponse) {
        message.success('Your promotional code has been added.');
        setDiscount(discountResponse);
      } else {
        message.error('Invalid promotional code.');
        setDiscount(0);
      }
      setPromoCodeIsLoading(false);
    },
    [promotionalCode, validatePromoCodeMutation]
  );

  const discountPrice = PRICE_IN_WEI - discountValue;

  const { data, isError, isLoading, isSuccess, sendTransaction } = useSendTransaction({
    request: {
      to: STAKING_ADDRESS,
      value: discountPrice
    }
  });

  useEffect(() => {
    if (isSuccess) {
      // Save the job in the DB
      handleSubmit(data?.hash);
      message.success('Payment successfull.');
    }
  }, [data?.hash, handleSubmit, isSuccess]);

  useEffect(() => {
    if (isError) {
      message.error('Error sending transaction');
    }
  }, [isError]);

  const handlePay = useCallback(
    async (e) => {
      e.preventDefault();

      if (activeChain?.id !== 1 && !isDev()) {
        message.error(
          `You're on the ${activeChain?.name} network. Please switch to the Ethereum mainnet.`
        );
        return;
      }

      const userBalanceInWei = Number(Web3.utils.toWei(userBalance.toString(), 'ether'));
      if (userBalanceInWei < discountPrice) {
        console.log({ userBalanceInWei, discountPrice });
        message.error('You have insufficient balance.');
        return;
      }
      sendTransaction();
    },
    [activeChain?.id, activeChain?.name, discountPrice, sendTransaction, userBalance]
  );

  if (isLoading) {
    return (
      <div>
        <Loader />
        <p>Payment in process...</p>
      </div>
    );
  }

  if (addJobMutationIsLoading) {
    return (
      <div>
        <Loader />
        <p>Saving your job post...</p>
      </div>
    );
  }

  return (
    <div>
      <h1 style={{ marginTop: 0 }}>Checkout</h1>

      <CheckoutLine>
        <div>Details:</div>
        <div>60 days listing</div>
      </CheckoutLine>

      <CheckoutLine>
        <div>Total</div>
        <RegularPrice discount={!!discount}>
          {PRICE_IN_ETHER}ETH {priceInUsd && `(≈ $${priceInUsd}US)`}
        </RegularPrice>
        {!!discount && (
          <div>
            {<PromoCode>DISCOUNT -{discount}% 🎉</PromoCode>}{' '}
            {Web3.utils.fromWei(discountPrice.toString())}ETH{' '}
            {priceInUsd && `(≈ $${(priceInUsd * ((100 - discount) / 100)).toFixed(2)}US)`}
          </div>
        )}
      </CheckoutLine>

      <PromoCodeWrapper>
        <Input
          value={promotionalCode}
          placeholder="Promotional code"
          onChange={handleChange}
          name="promotionalCode"
        />
        <Button
          type="submit"
          style={{ marginLeft: '20px' }}
          onClick={handleApplyPromoCode}
          loading={promoCodeIsLoading}>
          Apply
        </Button>
      </PromoCodeWrapper>

      <HR />

      <CheckoutBtnsWrapper>
        <Button type="button" onClick={() => setPageState('PREVIEW')}>
          Go back
        </Button>

        <Button
          style={{ marginLeft: '20px' }}
          type="button"
          disabled={isLoading || balanceLoading}
          onClick={handlePay}
          loading={isLoading || balanceLoading}>
          Pay
        </Button>
      </CheckoutBtnsWrapper>
    </div>
  );
};
