import styled from 'styled-components';
import { Styles } from 'styles';

export const ContentWrapper = styled.div`
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;

  :first-child {
    margin-top: 0 !important;
  }

  h2,
  h1 {
    padding-bottom: 8px;
    font-size: 1.5em;
    margin-top: 40px;
  }

  a {
    color: ${Styles.color.primary};
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  table tr {
    border-top: 1px solid ${Styles.color.text}22;
  }

  table th,
  table td {
    padding: 6px 13px;
    border: 1px solid ${Styles.color.text}22;
  }

  table tr:nth-child(even) {
    background: ${Styles.color.text}07;
  }

  ul,
  ol {
    padding-left: 32px;
  }

  img {
    max-width: 100%;
    border-radius: 8px;
    border: 1px solid ${Styles.color.text}11;
    display: flex;
    margin: 40px 0;
  }

  blockquote {
    padding: 0 1em;
    border-left: 4px solid #0001;
    color: ${Styles.color.text}55;
  }
`;
