import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export const useKeyPress = (targetKeys: string[] = [], element: any = window): any => {
  // State for keeping track of whether key is pressed
  const [keysPressed, setKeysPressed] = useState<any>(new Set([]));

  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    (e) => {
      const { key } = e;

      if (targetKeys?.includes(key)) {
        if (key === 'Enter') {
          e?.preventDefault();
        }

        const clonedKeysPressed = new Set(keysPressed);
        clonedKeysPressed.add(key);
        if (!isEqual(clonedKeysPressed, keysPressed)) {
          setKeysPressed(clonedKeysPressed);
        }
      }
    },
    [keysPressed, targetKeys]
  );

  // If released key is our target key then set to false
  const upHandler = useCallback(
    ({ key }): void => {
      if (targetKeys.includes(key)) {
        setKeysPressed(new Set([]));
      }
    },
    [targetKeys]
  );

  // Add event listeners
  useEffect(() => {
    element?.addEventListener('keydown', downHandler);
    element?.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      element?.removeEventListener('keydown', downHandler);
      element?.removeEventListener('keyup', upHandler);
    };
  }, [keysPressed, element]); // Empty array ensures that effect is only run on mount and unmount

  // Return true if all keys are in the set
  return keysPressed?.size === targetKeys?.length;
};
