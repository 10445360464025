export const getMenuItems = (resourcesDict) => [
  {
    emoji: '🗳️',
    name: 'Pending resources',
    path: '/resources/pending-resources',
    numOfResources: resourcesDict['pending-resources']?.length
  },
  {
    emoji: '🔥',
    name: 'Recently added',
    path: '/resources/recently-added',
    numOfResources: resourcesDict['recently-added']?.length
  },
  {
    emoji: '📖',
    name: 'Smart contract languages',
    path: '/resources/smart-contract-languages',
    numOfResources: resourcesDict['smart-contract-languages']?.length
  },
  {
    emoji: '🚀',
    name: 'Deployment & testing',
    path: '/resources/deployment',
    numOfResources: resourcesDict['deployment']?.length
  },
  {
    emoji: '👛',
    name: 'Wallets',
    path: '/resources/wallets',
    numOfResources: resourcesDict['wallets']?.length
  },
  {
    emoji: '👀',
    name: 'Block explorers',
    path: '/resources/block-explorers',
    numOfResources: resourcesDict['block-explorers']?.length
  },
  {
    emoji: '⚡',
    name: 'Layer 1 connection',
    path: '/resources/layer-1-connection',
    numOfResources: resourcesDict['layer-1-connection']?.length
  },
  {
    emoji: '🖥️',
    name: 'Front end tools',
    path: '/resources/front-end-tools',
    numOfResources: resourcesDict['front-end-tools']?.length
  },
  {
    emoji: '🗂️',
    name: 'Storage',
    path: '/resources/storage',
    numOfResources: resourcesDict['storage']?.length
  },
  {
    emoji: '🔒',
    name: 'Security Analysis',
    path: '/resources/security-analysis',
    numOfResources: resourcesDict['security-analysis']?.length
  },
  {
    emoji: '📈',
    name: 'Monitoring',
    path: '/resources/monitoring',
    numOfResources: resourcesDict['monitoring']?.length
  },
  {
    emoji: '📦',
    name: 'Others',
    path: '/resources/others',
    numOfResources: resourcesDict['others']?.length
  },
  {
    emoji: '🗄️',
    name: 'All resources',
    path: '/resources/all',
    numOfResources: resourcesDict['all']?.length
  },
  {
    emoji: '🔎',
    name: 'Search',
    path: '/search'
  }
];
