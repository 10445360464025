import styled from 'styled-components';
import { Styles } from 'styles';
import { messageIn, messageOut } from 'utils/keyframes';

const ANIM_DURATION = 0.2;

export const MessageWrapper = styled.div<{ time: number; ref: any; height: number }>`
  align-items: center;
  animation-fill-mode: forwards;
  backdrop-filter: blur(10px);
  background: ${Styles.color.black}bb;
  border: 2px solid;
  border-color: ${Styles.color.white}33;
  border-radius: ${Styles.borderRadius};
  display: flex;
  line-height: 1.5;
  margin: 4px;
  max-width: 100%;
  min-height: 40px;
  min-width: 200px;
  overflow: hidden;
  padding: 8px;
  pointer-events: none;
  position: relative;

  animation-name: ${messageIn}, ${(p) => messageOut(p.height)};
  animation-delay: 0s, ${(p) => p.time - ANIM_DURATION}s;
  animation-duration: ${ANIM_DURATION}s;
`;

export const TextWrapper = styled.div``;
