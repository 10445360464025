import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Styles } from 'styles';
import { slideInLeft } from 'utils/keyframes';

export const AppLayoutWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0 12px;
  box-sizing: border-box;
`;

export const TopNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;
`;

export const Logo = styled(Link)`
  text-decoration: none;
  font-weight: ${Styles.font.weight.bold};
  color: ${Styles.color.white};
  font-size: 24px;
  margin-top: 5px;
`;

export const MenuLink = styled(Link)<{ $isActive?: boolean }>`
  font-weight: normal;
  display: flex;
  align-items: center;
  font-size: 18px;
  min-width: 0 !important;
  white-space: nowrap;
  justify-content: flex-start;
  text-decoration: none;
  color: ${Styles.color.white};
  padding: 8px 8px;
  transition: 0.2s;
  border-radius: ${Styles.borderRadius};

  :hover {
    background: ${Styles.color.white}14;
  }

  ${(p) =>
    p.$isActive &&
    css`
      color: ${Styles.color.primary};
      background: ${Styles.color.primary}18 !important;
    `};
`;

export const MenuAndChild = styled.div`
  display: flex;
  margin-top: 40px;
`;

export const MenuItemsWrapper = styled.div`
  width: 300px;
  position: sticky;
  align-self: flex-start;
  top: 20px;
  margin-right: 40px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Emoji = styled.span`
  margin-right: 12px;
`;

export const NumOfRessources = styled.span`
  margin-left: 8px;
  opacity: 0.6;
  font-size: 12px;
`;

export const ChildrenWrapper = styled.div`
  width: calc(100% - 340px);

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const DesktopMenuItems = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const MobileMenuBtn = styled.div`
  @media (min-width: 1000px) {
    display: none;
  }
`;

export const MobileMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${Styles.color.black};
  z-index: 9999;
  padding: 12px;
  overflow: scroll;
`;

export const CloseBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const MobileMenuBtnsWrapper = styled.div`
  margin: 20px 0;

  button {
    width: 100%;
  }
`;

export const MenuItemsLinksWrapper = styled.div`
  opacity: 0;
  animation: 0.8s ${slideInLeft} forwards;
`;
