import { Icon } from 'components-library/icon';
import RCNotification from 'rc-notification';
import { NotificationInstance as RCNotificationInstance } from 'rc-notification/lib/Notification';
import React, { useEffect, useRef, useState } from 'react';
import { Styles } from 'styles';

import { MessageWrapper, TextWrapper } from './styled';

let notificationInstance: RCNotificationInstance | null = null;

RCNotification.newInstance(
  {
    maxCount: 5,
    style: {
      pointerEvents: 'none',
      position: 'fixed',
      right: 0,
      top: 0,
      transition: '2s',

      zIndex: 9999999
    }
  },
  (n) => {
    notificationInstance = n;
  }
);

const MessageComponent = ({ time, children }) => {
  const ref = useRef<HTMLDivElement>();
  const [messageHeight, setMessageHeight] = useState(0);

  useEffect(() => {
    setMessageHeight(ref.current?.clientHeight ?? 0);
  }, []);

  return (
    <MessageWrapper time={time} ref={ref} height={messageHeight}>
      {children}
    </MessageWrapper>
  );
};

const sendMessage = ({
  text,
  time = 3,
  type,
  icon = null
}: {
  text: string;
  time?: number;
  type: 'info' | 'success' | 'error' | 'custom';
  icon?: string | null;
}) => {
  // Change the appearence depending on the message type
  let messageIcon: string | null = icon;
  let iconColor = '#000000';
  switch (type) {
    case 'success':
      messageIcon = 'check_circle';
      iconColor = Styles.color.primary;
      break;
    case 'error':
      messageIcon = 'report';
      iconColor = Styles.color.danger;
      break;
    case 'info':
      messageIcon = 'info';
      iconColor = '#ccc';
      break;
    case 'custom':
    default:
      break;
  }

  const prefix = messageIcon ? (
    <Icon name={messageIcon} style={{ color: iconColor, marginRight: '8px' }} />
  ) : null;

  return notificationInstance?.notice({
    content: (
      <MessageComponent time={time}>
        {prefix}
        <TextWrapper>{text}</TextWrapper>
      </MessageComponent>
    ),
    duration: time
  });
};

export class message {
  static success(text: string, time?: number) {
    return sendMessage({ text, time, type: 'success' });
  }

  static error(text = 'Somehting went wrong...', time?: number) {
    return sendMessage({ text, time, type: 'error' });
  }

  static info(text: string, time?: number) {
    return sendMessage({ text, time, type: 'info' });
  }

  static customIcon(text: string, time?: number, icon?: string) {
    return sendMessage({ icon, text, time, type: 'custom' });
  }
}
