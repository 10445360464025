import { Button } from 'components-library/button';
import { useAccount, useDisconnect, useEnsAvatar, useEnsName } from 'wagmi';
import { ConnectBtns } from 'components/connect-btns';
import styled from 'styled-components';
import { useEffect } from 'react';

const UserPageWrapper = styled.div`
  max-width: 800px;
  margin: 40px auto;
`;

export const UserPage = () => {
  const { data: account } = useAccount();
  const { data: ensAvatar } = useEnsAvatar({ addressOrName: account?.address });
  const { data: ensName } = useEnsName({ address: account?.address });
  const { disconnect } = useDisconnect();

  useEffect(() => {
    localStorage.setItem('userAccountAddress', account?.address ?? '');
  }, [account?.address]);

  if (account) {
    return (
      <UserPageWrapper>
        <h1>User</h1>

        {ensAvatar && <img src={ensAvatar} alt="ENS Avatar" />}

        <p>
          Wallet: <code>{ensName ? `${ensName} (${account.address})` : account.address}</code>
        </p>

        <p>
          Connected to <code>{account?.connector?.name}</code>
        </p>
        <Button onClick={() => disconnect()}>Disconnect</Button>
      </UserPageWrapper>
    );
  }

  return <ConnectBtns />;
};
