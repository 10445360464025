import { Icon } from 'components-library/icon';
import { useKeyPress } from 'hooks/key-press';
import * as React from 'react';
import { useCallback, useEffect } from 'react';

import {
  ClearSearchBtn,
  ErrorMessage,
  IconWrapper,
  InputWrapper,
  Label,
  PositionRelativeWrapper,
  SearchIconWrapper
} from './styled';

/** Simple mapping object to map cmd name to unicode */
const cmdNameToSignDict = {
  Meta: '⌘',
  Shift: '⇧'
};

export const cmdNameToSign = (name) => cmdNameToSignDict[name] ?? name;

export const Input = (props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const slashPress: boolean = useKeyPress(props.searchCmd);

  useEffect(() => {
    if (props.searchCmd && slashPress) {
      inputRef?.current?.focus();
    }
  }, [props.searchCmd, slashPress]);

  const handleClearSearch = useCallback(() => {
    props.onChange({ target: { value: '' } });
    inputRef?.current?.focus();
  }, []);

  const hasEmptyValue = props.value.length === 0;
  const isSearchInput = props.type === 'search';

  const hasBottomMargin = props?.hasBottomMargin ?? true;

  return (
    <div>
      {props.label && (
        <Label>
          {props.label}
          {props.isOptional && ' (optional)'}
        </Label>
      )}
      <PositionRelativeWrapper hasError={props.error} $hasBottomMargin={hasBottomMargin}>
        <InputWrapper
          {...props}
          {...(props.textarea && { as: 'textarea', rows: props.rows ?? 2 })}
          autoComplete={props.autoComplete ?? 'off'}
          isCopiable={props.isCopiable}
          hasError={props.error}
          $width={props.width}
          ref={inputRef}
        />

        {props.isCopiable && (
          <IconWrapper>
            <Icon name="copy" />
          </IconWrapper>
        )}

        {isSearchInput &&
          (hasEmptyValue ? (
            <IconWrapper>
              {props?.searchCmd?.map((cmd) => (
                <SearchIconWrapper key={cmd}>{cmdNameToSign(cmd)}</SearchIconWrapper>
              ))}
            </IconWrapper>
          ) : (
            <ClearSearchBtn onClick={handleClearSearch}>
              <SearchIconWrapper>
                <Icon name="close" />
              </SearchIconWrapper>
            </ClearSearchBtn>
          ))}
      </PositionRelativeWrapper>

      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </div>
  );
};
