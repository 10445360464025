import { Button } from 'components-library/button';
import { AddCategoryWrapper } from '../styled';

export const CategoryForm = () => {
  return (
    <AddCategoryWrapper>
      <h1>New Category</h1>

      <p>
        Feel free to post new category idea via Discord so we can discuss about it and decide wether
        to add it to the current list or not.{' '}
      </p>

      <Button
        style={{ float: 'right', marginTop: '20px' }}
        href="https://discord.com/channels/968572310397259867/968573863220883516">
        Submit on Discord
      </Button>
    </AddCategoryWrapper>
  );
};
