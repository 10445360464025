import { Routes, Route } from 'react-router-dom';
import { CategoryForm } from './category-form';
import { RessourceForm } from './ressource-form';
import { JobForm } from './job-form';

export const NewFormRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/job" element={<JobForm />} />
        <Route path="/category" element={<CategoryForm />} />
        <Route path="/ressource" element={<RessourceForm />} />
      </Routes>
    </>
  );
};
