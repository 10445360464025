/* eslint-disable max-len */
import { Button } from 'components-library/button';
import { Loader } from 'components-library/loader';
import { Tag, Tags } from 'components/jobs-listing/job-card';
import { MarkdownContent } from 'components/markdown-content';
import { HR } from 'components/new-form-router/styled';
import { BottomStaticButtonWrapper } from 'components/ressource-card-list';
import { useJobs } from 'hooks/jobs';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Styles } from 'styles';

const Position = styled.span``;

const Compensation = styled.p``;

const Location = styled.p``;

const Description = styled.p`
  font-size: 18px;
`;

const PostedAgo = styled.p``;

const Img = styled.img`
  height: 120px;
  width: 120px;
  border-radius: ${Styles.borderRadius};
  border: 1px solid ${Styles.color.white}99;
  margin-right: 12px;
  transition: 0.2s;

  :hover {
    border-color: ${Styles.color.white};
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Header = styled.h1`
  margin-top: 0;
`;

const MainWrapper = styled.div`
  margin-bottom: 80px;
`;

export const JobPage = () => {
  const { id: jobPostId } = useParams();
  const { jobs, loading } = useJobs();

  const currentJob = jobs?.find((job) => job._id === jobPostId);

  if (loading) {
    return <Loader />;
  }

  const {
    companyName,
    position,
    minSalary,
    maxSalary,
    location,
    tags,
    applyUrl,
    logoUrl,
    description,
    createdAt
  } = currentJob;

  return (
    <JobPageContent
      {...{
        companyName,
        position,
        minSalary,
        maxSalary,
        location,
        tags,
        applyUrl,
        logoUrl,
        description,
        createdAt
      }}
    />
  );
};

export const JobPageContent = ({
  companyName,
  position,
  minSalary,
  maxSalary,
  location,
  tags,
  applyUrl,
  logoUrl,
  isPreviewMode = false,
  description,
  createdAt
}) => {
  return (
    <div>
      <MainWrapper>
        <HeaderWrapper>
          <div>
            <Header>
              {companyName} <span style={{ opacity: '0.4' }}>is hiring a</span>{' '}
              <Position>{position}</Position>
            </Header>

            <Compensation>
              Compensation: {minSalary && maxSalary ? `${minSalary} - ${maxSalary}` : 'unknown'}
            </Compensation>

            <Location>Location: {location}</Location>

            <PostedAgo>Posted {moment(Number(createdAt)).fromNow()}</PostedAgo>

            <Tags>{tags?.map((tag) => tag && <Tag key={tag}>{tag}</Tag>)}</Tags>
          </div>

          {logoUrl && (
            <a href={applyUrl} target="_blank" rel="noreferrer">
              <Img src={logoUrl} />
            </a>
          )}
        </HeaderWrapper>

        <HR />

        <Description>
          <MarkdownContent content={description} />
        </Description>
      </MainWrapper>

      {!isPreviewMode && (
        <BottomStaticButtonWrapper>
          <Button href={applyUrl} rightIcon="launch" style={{ minWidth: '180px' }}>
            Apply
          </Button>
        </BottomStaticButtonWrapper>
      )}
    </div>
  );
};
