import { Button } from 'components-library/button';
import { Input } from 'components-library/input';
import Select from 'components-library/select';
import { useCallback, useEffect, useState } from 'react';
import { Form, HR } from '../styled';
import { useMutation } from '@apollo/client';
import { ADD_RESSOURCE } from 'utils/gql';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { message } from 'components-library/message';
import { isValidUrl } from 'utils/is-valid-url';
import { useRessources } from 'hooks/ressources';
import { useAccount } from 'wagmi';

const CATEGORY_LIST = [
  'Smart contract languages',
  'Deployment',
  'Wallets',
  'Block explorers',
  'Layer 1 connection',
  'Front end tools',
  'Security Analysis',
  'Monitoring',
  'Storage',
  'Others'
];

const categoryOptions = CATEGORY_LIST.map((ressource) => ({
  label: ressource,
  value: ressource.toLowerCase().replaceAll(' ', '-')
}));

export const RessourceForm = () => {
  // Get the category in the search param if there is one
  const [searchParams] = useSearchParams();
  const currentCategory = searchParams.get('category') ?? categoryOptions[0].value;
  const { refetch } = useRessources();
  const { data: account } = useAccount();

  const [title, setTitle] = useState('');
  const [titleErrorMsg, setTitleErrorMsg] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState('');
  const [url, setUrl] = useState('https://');
  const [urlErrorMsg, setUrlErrorMsg] = useState('');
  const [category, setCategory] = useState(currentCategory);
  const [categoryErrorMsg, setCategoryErrorMsg] = useState('');

  const navigate = useNavigate();

  const [addRessourceMutation, { loading: addRessourceIsLoading }] = useMutation(ADD_RESSOURCE);

  const handleChange = useCallback((e) => {
    if (e.target.name === 'title') {
      setTitleErrorMsg('');
      setTitle(e.target.value);
    }
    if (e.target.name === 'url') {
      setUrlErrorMsg('');
      setUrl(e.target.value);
    }

    if (e.target.name === 'description') {
      setDescriptionErrorMsg('');
      setDescription(e.target.value);
    }

    if (e.target.name === 'category') {
      setCategoryErrorMsg('');
      setCategory(e.target.value);
    }
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      let hasError = false;
      e.preventDefault();
      if (title.length < 4) {
        setTitleErrorMsg('The title of the ressource should be at least 4 characters.');
        hasError = true;
      }
      if (!url.length || !isValidUrl(url)) {
        setUrlErrorMsg('Please enter a valid URL.');
        hasError = true;
      }
      if (description.length < 20) {
        setDescriptionErrorMsg('The description should be at least 20 characters.');
        hasError = true;
      }
      if (description.length > 300) {
        setDescriptionErrorMsg('The description should not contain more than 300 characters.');
        hasError = true;
      }
      if (!category) {
        setCategoryErrorMsg('Please select a category.');
        hasError = true;
      }
      if (!hasError) {
        const newRessourceResponse = await addRessourceMutation({
          variables: {
            title,
            url,
            description,
            category
          }
        });

        // Send success message
        message.success(
          `${newRessourceResponse?.data?.addRessource?.ressource?.title} has been added!`
        );
        // refetch data
        await refetch();
        navigate('/resources/pending-resources');
      }
    },
    [addRessourceMutation, category, description, navigate, refetch, title, url]
  );

  useEffect(() => {
    if (!account) {
      navigate('/user');
    }
  });

  return (
    <Form onSubmit={handleSubmit}>
      <h1>New Resource</h1>

      <p>
        Once submitted, your submission will be pending. If it reached 5 upvotes in the next 7 days,
        it will be added to the permanent ressources list.
      </p>

      <HR />

      <Input
        value={title}
        label="Enter the title of the ressource"
        placeholder="Solidity, Rust, Vyper..."
        onChange={handleChange}
        name="title"
        error={titleErrorMsg}
        maxLength={50}
      />

      <Input
        value={url}
        label="Enter the URL of the ressource (starting with https:// or http://)"
        placeholder="https://docs.soliditylang.org"
        onChange={handleChange}
        name="url"
        error={urlErrorMsg}
      />

      <Input
        value={description}
        label="Description"
        placeholder="Brief description of the ressource (300 characters max)."
        textarea
        onChange={handleChange}
        name="description"
        error={descriptionErrorMsg}
        maxLength={300}
        rows="6"
      />

      <Select
        id="category"
        label="Category"
        placeholder="Select a category:"
        options={categoryOptions}
        onChange={handleChange}
        name="category"
        value={category}
        error={categoryErrorMsg}
      />

      <Button
        type="submit"
        loading={addRessourceIsLoading}
        style={{ float: 'right', marginTop: '20px' }}>
        Submit
      </Button>
    </Form>
  );
};
