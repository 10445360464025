import { Button } from 'components-library/button';
import { JobPageContent } from 'components/job-page';
import { JobCard } from 'components/jobs-listing/job-card';
import styled from 'styled-components';
import { Styles } from 'styles';

const JobPageContentWrapper = styled.div`
  border: 1px solid ${Styles.color.white}22;
  border-radius: ${Styles.borderRadius};
  padding: 20px;
`;

export const Preview = ({
  companyName,
  description,
  position,
  minSalary,
  maxSalary,
  location,
  tag1,
  tag2,
  tag3,
  tag4,
  applyUrl,
  logoUrl,
  setPageState
}) => {
  return (
    <>
      <h1 style={{ marginTop: 0 }}>Preview</h1>

      <h4>Job card:</h4>
      <JobCard
        position={position}
        tags={[tag1, tag2, tag3, tag4]}
        company={companyName}
        location={location}
        id={'preview'}
        logoUrl={logoUrl}
        activeTags={[]}
      />

      <h4>Job page content:</h4>
      <JobPageContentWrapper>
        <JobPageContent
          {...{
            companyName,
            description,
            position,
            minSalary,
            maxSalary,
            location,
            tags: [tag1, tag2, tag3, tag4],
            applyUrl,
            logoUrl,
            isPreviewMode: true,
            createdAt: Date.now()
          }}
        />
      </JobPageContentWrapper>

      <>
        <Button
          type="button"
          style={{ float: 'right', margin: '20px' }}
          onClick={() => setPageState('CHECKOUT')}>
          Checkout
        </Button>

        <Button
          type="button"
          style={{ float: 'right', margin: '20px' }}
          onClick={() => setPageState('FORM')}>
          Go back
        </Button>
      </>
    </>
  );
};
