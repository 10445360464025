import { useMutation } from '@apollo/client';
import { Button } from 'components-library/button';
import { Input } from 'components-library/input';
import { RessourceCard } from 'components/ressource-card';
import { RessourceCardListWrapper } from 'components/ressource-card-list';
import { useRessources } from 'hooks/ressources';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { SEARCH_RESSOURCES } from 'utils/gql';

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 20px;
`;

export const SearchPage = () => {
  const [searchString, setSearchString] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [searchRessources, { loading }] = useMutation(SEARCH_RESSOURCES);
  const [searchResults, setSearchResults] = useState([]);
  const { refetch } = useRessources();

  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = useCallback(
    async (e) => {
      e.preventDefault();
      if (searchString.length < 3) {
        setErrMsg('Please enter at least 3 characters.');
        return;
      }

      const results = await searchRessources({ variables: { searchString } });
      setSearchResults(results?.data?.searchRessources?.ressources);
      setHasSearched(true);
    },
    [searchRessources, searchString]
  );

  const handleChange = useCallback((e) => {
    setErrMsg('');
    setSearchString(e.target.value);
  }, []);

  return (
    <div>
      <h1 style={{ marginTop: '0' }}>Search ressources</h1>

      <Form onSubmit={handleSearch}>
        <Input
          value={searchString}
          placeholder="solidity, etherscan, etc."
          onChange={handleChange}
          autoFocus
          error={errMsg}
        />
        <Button style={{ marginLeft: '20px' }} loading={loading}>
          Search
        </Button>
      </Form>

      {!loading && (
        <RessourceCardListWrapper>
          {searchResults?.map(
            ({ description, title, upvotes, _id, url, downvotes, createdAt, category }) => (
              <RessourceCard
                key={_id}
                description={description}
                title={title}
                upvotes={upvotes}
                downvotes={downvotes}
                url={url}
                id={_id}
                refetch={refetch}
                createdAt={createdAt}
                category={category}
                showCategory={true}
                showUrl={false}
              />
            )
          )}
          {hasSearched && !searchResults?.length && <p>There is no result for your search.</p>}
        </RessourceCardListWrapper>
      )}
    </div>
  );
};
