export const Styles = {
  borderRadius: '4px',
  font: {
    weight: {
      normal: '300',
      bold: '600'
    }
  },
  borderWidth: '2px',
  color: {
    primary: '#00f699',
    // primary: '#00d7aa',
    // primary: '#ff754a',
    white: '#ffffff',
    black: '#040e1c',
    text: '#ffffff',
    danger: '#ff3030'
  }
};
