import { Homepage } from 'components/homepage';
import { JobPage } from 'components/job-page';
import { JobsListing } from 'components/jobs-listing';
import { NewFormRouter } from 'components/new-form-router';
import { RessourceCardList } from 'components/ressource-card-list';
import { SearchPage } from 'components/search-page';
import { TermOfServicePage } from 'components/term-of-service-page';
import { UserPage } from 'components/user-page';
import { useGoogleAnalytics } from 'hooks/google-analytics';
import { Routes, Route } from 'react-router-dom';

export const AppRouter = () => {
  useGoogleAnalytics();

  return (
    <>
      <Routes>
        <Route path="/resources/*" element={<RessourceCardList />} />

        <Route path="" element={<Homepage />} />

        <Route path="/jobs" element={<JobsListing />} />

        <Route path="/jobs/:id" element={<JobPage />} />

        <Route path="/user" element={<UserPage />} />

        <Route path="/new/*" element={<NewFormRouter />} />

        <Route path="/terms-of-service" element={<TermOfServicePage />} />

        <Route path="/search" element={<SearchPage />} />
      </Routes>
    </>
  );
};
