// import { ProductHuntBtnOnly } from 'components/product-hunt-button';
// import { AppLayoutWrapper } from 'components-library/app-layout/styled';
// import { SHOW_PRODUCT_HUNT_BTN } from 'configs';
import { Icon } from 'components-library/icon';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  ColumnTitle,
  ColumnWrapper,
  CopyrightPar,
  FooterLink,
  FooterWrapper,
  InnerFooterWrapper
} from './styled';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const footerData = [
  {
    content: [
      {
        href: 'https://discord.com/channels/968572310397259867/968573863220883516',
        title: 'Discord'
      },
      { href: 'https://twitter.com/web3_tools', title: 'Twitter' }
    ],
    title: 'Community'
  },
  {
    content: [
      { title: 'Post a job', to: '/new/job' },
      { title: 'Jobs listing', to: '/jobs' }
    ],
    title: 'Jobs'
  },
  {
    content: [
      { title: 'Pending resources', to: '/resources/pending-resources' },
      { title: 'Recently added', to: '/resources/recently-added' },
      { title: 'All time favorites', to: '/resources/all' }
    ],
    title: 'Resources'
  },
  {
    content: [
      { title: 'Terms of service', to: '/terms-of-service' },
      { href: 'mailto:contact@web3-tools.xyz', title: 'Contact' }
    ],
    title: 'Others'
  }
];

export const Footer: FC = () => {
  return (
    <>
      <FooterWrapper>
        <div>
          <InnerFooterWrapper>
            {footerData.map(({ title, content }) => (
              <ColumnWrapper key={title}>
                <ColumnTitle>{title}</ColumnTitle>
                {content.map(({ title: contentTitle, to, href }) =>
                  to ? (
                    <FooterLink to={to} key={contentTitle}>
                      {contentTitle}
                    </FooterLink>
                  ) : (
                    <FooterLink as="a" href={href} target="_blank" key={contentTitle}>
                      {contentTitle}
                      <Icon name="launch" style={{ paddingLeft: '8px' }} />
                    </FooterLink>
                  )
                )}
              </ColumnWrapper>
            ))}
          </InnerFooterWrapper>

          {/* {SHOW_PRODUCT_HUNT_BTN && <ProductHuntBtnOnly />} */}

          <CopyrightPar>Copyright © {new Date().getFullYear()} web3-tools</CopyrightPar>
        </div>
      </FooterWrapper>

      <ScrollToTop />
    </>
  );
};
