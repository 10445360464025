import { createGlobalStyle } from 'styled-components';
import { Styles } from 'styles';

export const GlobalStyles = createGlobalStyle<{ theme: any }>`
  body {
    background: ${Styles.color.black};
    color: ${Styles.color.text};
  }

  ::selection {
    background: ${Styles.color.primary};
    color: ${Styles.color.black};
  }

  ::-moz-selection {
    background: ${Styles.color.primary};
    color: ${Styles.color.black};
  }
`;
