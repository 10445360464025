/* eslint-disable max-len */
import { Button } from 'components-library/button';
import { Footer } from 'components/footer';
import { NewsletterForm } from 'components/newsletter-form';
import { ProductHuntBtnOnly, ProductHuntButton } from 'components/product-hunt-button';
import { RessourceCard } from 'components/ressource-card';
import { categoryDescription } from 'components/ressource-card-list';
import { useRessources } from 'hooks/ressources';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Styles } from 'styles';
import { fadeIn, slideInBottom } from 'utils/keyframes';

const HomaPageWrapper = styled.div`
  /* display: flex; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
  margin-bottom: 80px;
  /* min-height: 100vh; */

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const HeroText = styled.div``;

const VideoWrapper = styled.div`
  width: 100%;
  padding-bottom: 100%;
  border-radius: ${Styles.borderRadius};
  overflow: hidden;
  position: relative;
  background: ${Styles.color.white}22;

  opacity: 0;
  animation: 1s 0.3s ${slideInBottom} forwards;

  @media (max-width: 1200px) {
    padding-bottom: 50%;
  }
`;

const Video = styled.video`
  border-radius: ${Styles.borderRadius};
  max-width: 100%;
  height: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center center;
  z-index: 0;
`;

const Header = styled.h1`
  font-size: 60px;
  margin: 0;

  opacity: 0;
  animation: 0.2s 0.3s ${slideInBottom} forwards;

  @media (max-width: 1200px) {
    font-size: 40px;
  }
`;

const SubTitle = styled.p`
  line-height: 1.4;
  font-size: 18px;
  color: ${Styles.color.white}88;

  opacity: 0;
  animation: 0.4s 0.3s ${slideInBottom} forwards;
`;

const Emphasis = styled.span`
  color: ${Styles.color.primary};
  /* font-weight: bold; */
`;

const MainRessourcesWrapper = styled.div`
  margin-bottom: 80px;
  min-height: 200px;
`;

const RessourcesWrapper = styled.div`
  display: flex;
  overflow: scroll;
  padding-bottom: 20px;
`;

const CardWrapper = styled.div`
  min-width: 340px;
  display: grid;
  margin-right: 20px;
  /* height: 200px; */
  /* background: #fff3; */
`;

const TitleAndLink = styled.div`
  display: flex;
  align-items: baseline;
`;

const CarousselHeader = styled.h3`
  font-size: 24px;
  margin: 0;
`;

const CarousselPar = styled.p`
  opacity: 0.6;
`;

const CarousselLink = styled(Link)`
  color: ${Styles.color.primary};
  margin-left: 20px;
`;

const ButtonWrapper = styled.div`
  opacity: 0;
  animation: 0.6s 0.3s ${slideInBottom} forwards;

  @media (max-width: 1200px) {
    button {
      width: 100%;
      margin: 0 !important;
      margin-bottom: 20px !important;
    }
  }
`;

const SecondaryContent = styled.div`
  opacity: 0;
  animation: 1.6s 0.3s ${slideInBottom} forwards;
`;

const HomePageCaroussel = ({ ressources, title, text, link }) => {
  const { loading, refetch } = useRessources();

  return (
    <div>
      <TitleAndLink>
        <CarousselHeader>{title}</CarousselHeader>
        <CarousselLink to={link}>
          See more <span>→</span>
        </CarousselLink>
      </TitleAndLink>
      <CarousselPar>{text}</CarousselPar>
      <MainRessourcesWrapper>
        <RessourcesWrapper>
          {loading && <p>Loading data...</p>}
          {/* Only taking the first 4 items, for the rest, we need to go to the page */}
          {ressources
            ?.slice(0, 4)
            ?.map(({ description, title, upvotes, _id, url, downvotes, createdAt, category }) => (
              <CardWrapper key={_id}>
                <RessourceCard
                  description={description}
                  title={title}
                  upvotes={upvotes}
                  downvotes={downvotes}
                  url={url}
                  id={_id}
                  createdAt={createdAt}
                  category={category}
                  showCategory
                  showUrl={false}
                  refetch={refetch}
                />
              </CardWrapper>
            ))}

          {!loading && !ressources?.length && <p>Nothing there for the moment.</p>}
        </RessourcesWrapper>
      </MainRessourcesWrapper>
    </div>
  );
};

export const Homepage = () => {
  const { ressourcesDict } = useRessources();

  return (
    <div>
      <HomaPageWrapper>
        <HeroText>
          <Header>Find quickly the best web3 developer tools</Header>

          <SubTitle>
            Welcome to the first{' '}
            <Emphasis>community-led web3 developer resources platform</Emphasis>. Browse, Add and
            Vote for your favorite web3 developer tools.
          </SubTitle>

          <ButtonWrapper>
            <Button to="/resources/all">Most popular</Button>
            <Button to="/resources/pending-resources" style={{ marginLeft: '20px' }}>
              Pending resources
            </Button>
          </ButtonWrapper>
        </HeroText>

        <VideoWrapper>
          <Video loop muted autoPlay playsInline>
            <source src="https://static.vecteezy.com/system/resources/previews/003/700/769/mp4/3d-multicolored-fluid-wave-pattern-abstract-background-free-video.mp4" />
          </Video>
        </VideoWrapper>
      </HomaPageWrapper>

      <SecondaryContent>
        <HomePageCaroussel
          ressources={ressourcesDict['pending-resources']}
          title="🗳️ Pending resources"
          text={categoryDescription['pending-resources']}
          link="/resources/pending-resources"
        />

        <HomePageCaroussel
          ressources={ressourcesDict['recently-added']}
          title="🔥 Recently added"
          text={categoryDescription['recently-added']}
          link="/resources/recently-added"
        />

        <HomePageCaroussel
          ressources={ressourcesDict['all']}
          title="🎖️ All time favorites"
          text={categoryDescription['all']}
          link="/resources/all"
        />

        <NewsletterForm />

        <Footer />
      </SecondaryContent>

      <ProductHuntButton />
    </div>
  );
};
