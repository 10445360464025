interface ILoggerOption {
  description?: string;
  id?: string;
}

enum LOG_TYPE {
  ERROR = 'Error',
  INFO = 'Info',
  SESSION_START = 'SessionStart'
}

/** Logger funstion that sent user and app info to the admin tool. */
export class Logger {
  static async error(log, options?: ILoggerOption) {
    const description = options?.description ?? '';
    const id = options?.id ?? '';
    console.log({ description, log, logId: id, type: LOG_TYPE.ERROR });
  }

  static async info(log, options?: ILoggerOption) {
    const description = options?.description ?? '';
    const id = options?.id ?? '';
    console.log({ description, log, logId: id, type: LOG_TYPE.INFO });
  }
}
