import { Button } from 'components-library/button';
import { Input } from 'components-library/input';
import { Label } from 'components-library/input/styled';
import Select from 'components-library/select';
import { useCallback, useEffect, useState } from 'react';
import { Form, HR, Tags, Price } from '../styled';
import { useMutation } from '@apollo/client';
import { Logger } from 'utils/logger';
import { ADD_JOB } from 'utils/gql';
import { message } from 'components-library/message';
import { Preview } from './preview';
import { Checkout, PRICE_IN_ETHER } from './checkout';
import { useNavigate } from 'react-router-dom';
import { useJobs } from 'hooks/jobs';
import { getEtherPriceInUsd } from 'utils/get-ethereum-price';
import { isValidUrl } from 'utils/is-valid-url';
import { useAccount } from 'wagmi';

function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const DEFAULT_ERROR_MSG = 'This field is required.';

export const JOBS_TAGS = [
  'analyst',
  'backend',
  'community manager',
  'cto',
  'customer support',
  'dao',
  'defi',
  'design',
  'devops',
  'ether.js',
  'entry level',
  'front-end',
  'full-stack',
  'game dev',
  'golang',
  'intern',
  'java',
  'javascript',
  'marketing',
  'mobile',
  'nft',
  'node',
  'non tech',
  'product manager',
  'project manager',
  'react',
  'react native',
  'ruby',
  'rust',
  'sales',
  'scala',
  'senior',
  'solana',
  'solidity',
  'vyper',
  'web3js'
];

const salaryRangeOptions = [
  ...Array(80)
    .fill(null)
    .map((_, i) => `$${i * 5}k`)
    .map((val) => ({ label: val, value: val })),
  { label: '+$400k', value: '+$400k' }
];

export const JobForm = () => {
  const { data: account } = useAccount();

  const [priceInUsd, setPriceInUsd] = useState<string | undefined>();

  const navigate = useNavigate();
  const { refetch: refetchJobs } = useJobs();

  const [pageState, setPageState] = useState('FORM');

  const [position, setPosition] = useState('');
  const [positionErrorMsg, setPositionErrorMsg] = useState('');

  const [description, setDescription] = useState('');
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [companyNameErrorMsg, setCompanyNameErrorMsg] = useState('');

  const [logoUrl, setLogoUrl] = useState('');

  const [location, setLocation] = useState('');
  const [locationErrorMsg, setLocationErrorMsg] = useState('');

  const [applyUrl, setApplyUrl] = useState('https://');
  const [applyUrlErrorMsg, setApplyUrlErrorMsg] = useState('');

  const [email, setEmail] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');

  const [promotionalCode, setpromotionalCode] = useState('');

  const [tag1, setTag1] = useState('');
  const [tag2, setTag2] = useState('');
  const [tag3, setTag3] = useState('');
  const [tag4, setTag4] = useState('');

  const [minSalary, setMinSalary] = useState('');
  const [minSalaryErrorMsg, setMinSalaryErrorMsg] = useState('');

  const [maxSalary, setMaxSalary] = useState('');
  const [maxSalaryErrorMsg, setMaxSalaryErrorMsg] = useState('');

  const [addJobMutation, { loading: addJobMutationIsLoading }] = useMutation(ADD_JOB);

  const handleChange = useCallback((e) => {
    if (e.target.name === 'position') {
      setPositionErrorMsg('');
      setPosition(e.target.value);
    }
    if (e.target.name === 'description') {
      setDescriptionErrorMsg('');
      setDescription(e.target.value);
    }
    if (e.target.name === 'companyName') {
      setCompanyNameErrorMsg('');
      setCompanyName(e.target.value);
    }
    if (e.target.name === 'logoUrl') {
      setLogoUrl(e.target.value);
    }
    if (e.target.name === 'location') {
      setLocationErrorMsg('');
      setLocation(e.target.value);
    }
    if (e.target.name === 'applyUrl') {
      setApplyUrlErrorMsg('');
      setApplyUrl(e.target.value);
    }
    if (e.target.name === 'minSalary') {
      setMinSalaryErrorMsg('');
      setMinSalary(e.target.value);
    }
    if (e.target.name === 'maxSalary') {
      setMaxSalaryErrorMsg('');
      setMaxSalary(e.target.value);
    }
    if (e.target.name === 'promotionalCode') {
      setpromotionalCode(e.target.value);
    }
    if (e.target.name === 'email') {
      setEmailErrorMsg('');
      setEmail(e.target.value);
    }
  }, []);

  const handleNext = useCallback(
    (e) => {
      e.preventDefault();
      let error = false;
      if (!position.length) {
        setPositionErrorMsg(DEFAULT_ERROR_MSG);
        error = true;
      }
      if (!description.length) {
        setDescriptionErrorMsg(DEFAULT_ERROR_MSG);
        error = true;
      }
      if (!companyName.length) {
        setCompanyNameErrorMsg(DEFAULT_ERROR_MSG);
        error = true;
      }
      if (!location.length) {
        setLocationErrorMsg(DEFAULT_ERROR_MSG);
        error = true;
      }
      if (!applyUrl.length || !isValidUrl(applyUrl)) {
        setApplyUrlErrorMsg('Please enter a valid URL.');
        error = true;
      }
      if (!minSalary.length) {
        setMinSalaryErrorMsg(DEFAULT_ERROR_MSG);
        error = true;
      }
      if (!maxSalary.length) {
        setMaxSalaryErrorMsg(DEFAULT_ERROR_MSG);
        error = true;
      }

      if (!validateEmail(email)) {
        setEmailErrorMsg('Please enter a valid email.');
      }

      if (error) {
        message.error('Some fields are missing.');
        return;
      }
      setPageState('PREVIEW');
    },
    [
      position.length,
      description.length,
      companyName.length,
      location.length,
      applyUrl,
      minSalary.length,
      maxSalary.length,
      email
    ]
  );

  const handleSubmit = useCallback(
    async (transactionHash) => {
      try {
        const response = await addJobMutation({
          variables: {
            position,
            description,
            companyName,
            logoUrl,
            location,
            applyUrl,
            tag1,
            tag2,
            tag3,
            tag4,
            promotionalCode,
            minSalary,
            maxSalary,
            transactionHash,
            email
          }
        });

        // Refetch the jobs to get the new one
        await refetchJobs();

        message.success('Your job has successfully been added 🎉');
        // Navigate to the job post URL
        navigate(`/jobs/${response?.data?.addJob?.job?._id}`);
      } catch (err) {
        Logger.error(err);
        message.error();
      }
    },
    [
      addJobMutation,
      applyUrl,
      companyName,
      description,
      location,
      logoUrl,
      maxSalary,
      minSalary,
      navigate,
      position,
      promotionalCode,
      refetchJobs,
      tag1,
      tag2,
      tag3,
      tag4,
      email
    ]
  );

  useEffect(() => {
    const convertEthToUsd = async () => {
      const convertedPriceInUsd = await getEtherPriceInUsd(PRICE_IN_ETHER);
      setPriceInUsd(convertedPriceInUsd);
    };

    convertEthToUsd();
  }, []);

  useEffect(() => {
    if (!account) {
      navigate('/user');
      message.error('Please connect with wallet first.');
    }
  }, [account, navigate]);

  const tagOptions = JOBS_TAGS.map((tag) => ({ label: tag, value: tag }));

  // Remove the non valid range values and removing the min and max value so the user is never in a bad state.
  const sliceMinIdx = salaryRangeOptions.findIndex((p) => p.value == maxSalary);
  const sliceMaxIdx = salaryRangeOptions.findIndex((p) => p.value == minSalary);

  const minSalaryRangeOptions = salaryRangeOptions
    .slice(0, salaryRangeOptions.length)
    .slice(0, sliceMinIdx);
  const maxSalaryRangeOptions = salaryRangeOptions.slice(1).slice(sliceMaxIdx + 1);

  return (
    <Form>
      {pageState === 'FORM' && (
        <>
          <h1 style={{ marginTop: 0 }}>Post a web3 job</h1>

          <p>
            The price for 60 days listing is{' '}
            <Price>
              {PRICE_IN_ETHER}ETH {priceInUsd && `(≈ $${priceInUsd}US)`}
            </Price>
            .
          </p>

          <HR />

          <Input
            value={position}
            label="Position"
            placeholder="Software engineer"
            onChange={handleChange}
            name="position"
            error={positionErrorMsg}
            maxLength={60}
          />

          <Input
            value={description}
            // eslint-disable-next-line max-len
            label="Full description of the job and what your company/project is doing (supports markdown) (5k characters max)"
            placeholder="Description"
            textarea
            onChange={handleChange}
            name="description"
            rows="20"
            error={descriptionErrorMsg}
            maxLength={5000}
          />

          <Input
            value={companyName}
            label="Company name"
            placeholder="Coinbase, Moralis, Stripe..."
            onChange={handleChange}
            name="companyName"
            error={companyNameErrorMsg}
            maxLength={60}
          />

          <Label>Salary range</Label>
          <Tags>
            <Select
              id="minSalary"
              placeholder="Min yearly salary"
              options={minSalaryRangeOptions}
              onChange={handleChange}
              name="minSalary"
              value={minSalary}
              error={minSalaryErrorMsg}
            />

            <Select
              id="maxSalary"
              placeholder="Max yearly salary"
              options={maxSalaryRangeOptions}
              onChange={handleChange}
              name="maxSalary"
              value={maxSalary}
              error={maxSalaryErrorMsg}
            />
          </Tags>

          <Input
            value={location}
            label="Location"
            placeholder="Remote, Montreal, San Fransisco..."
            onChange={handleChange}
            name="location"
            error={locationErrorMsg}
            maxLength={60}
          />

          <Input
            value={applyUrl}
            label="Apply url (starting with https:// or http://)"
            placeholder="Apply URLs with a form which an applicant can fill out"
            onChange={handleChange}
            name="applyUrl"
            error={applyUrlErrorMsg}
          />

          <Input
            value={logoUrl}
            label="Link to your square logo (starting with https:// or http://) (1:1 ratio)"
            placeholder="e.g. Github or Twitter profile picture"
            onChange={handleChange}
            name="logoUrl"
            isOptional
          />

          <Label>Tags</Label>
          <Tags>
            <Select
              id="tag1"
              placeholder="Tag 1"
              options={tagOptions.filter((tag) => ![tag2, tag3, tag4].includes(tag.value))}
              onChange={(e) => setTag1(e.target.value)}
              name="tag1"
              value={tag1}
            />

            <Select
              id="tag2"
              placeholder="Tag 2"
              options={tagOptions.filter((tag) => ![tag1, tag3, tag4].includes(tag.value))}
              onChange={(e) => setTag2(e.target.value)}
              name="tag2"
              value={tag2}
            />

            <Select
              id="tag3"
              placeholder="Tag 3"
              options={tagOptions.filter((tag) => ![tag1, tag2, tag4].includes(tag.value))}
              onChange={(e) => setTag3(e.target.value)}
              name="tag3"
              value={tag3}
            />

            <Select
              id="tag4"
              placeholder="Tag 4"
              options={tagOptions.filter((tag) => ![tag1, tag2, tag3].includes(tag.value))}
              onChange={(e) => setTag4(e.target.value)}
              name="tag4"
              value={tag4}
            />
          </Tags>

          <Input
            value={email}
            label="Email for invoices (Stays private)"
            placeholder="jane@doe.com"
            onChange={handleChange}
            name="email"
            error={emailErrorMsg}
          />

          <Button type="submit" style={{ float: 'right', margin: '20px 0' }} onClick={handleNext}>
            Next
          </Button>
        </>
      )}
      {pageState === 'PREVIEW' && (
        <Preview
          {...{
            companyName,
            description,
            position,
            minSalary,
            maxSalary,
            location,
            tag1,
            tag2,
            tag3,
            tag4,
            applyUrl,
            logoUrl,
            setPageState
          }}
        />
      )}

      {pageState === 'CHECKOUT' && (
        <Checkout
          setPageState={setPageState}
          handleSubmit={handleSubmit}
          promotionalCode={promotionalCode}
          handleChange={handleChange}
          addJobMutationIsLoading={addJobMutationIsLoading}
          priceInUsd={priceInUsd}
        />
      )}
    </Form>
  );
};
