import { UnStyledLink } from 'components-library/button/styled';
import styled from 'styled-components';
import { Styles } from 'styles';

export const FooterWrapper = styled.div`
  margin-top: 80px;
  padding: 40px 0 20px;
  border-top: 1px solid ${Styles.color.white}33;
`;

export const InnerFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const ColumnWrapper = styled.div`
  width: 25%;

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 40px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const ColumnTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 20px;
  opacity: 0.6;
`;

export const FooterLink = styled(UnStyledLink)`
  text-decoration: none;
  display: block;
  padding-bottom: 12px;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
  color: ${Styles.color.white};
  text-decoration: underline ${Styles.color.white}44;

  :hover {
    text-decoration: underline ${Styles.color.primary};
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const CopyrightPar = styled.p`
  margin: 0;
  padding: 40px 0 20px 0;
  opacity: 0.7;

  @media (max-width: 600px) {
    text-align: center;
  }
`;
