import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { ContentWrapper } from './styled';

export const MarkdownContent = ({ content }: { content: string }) => (
  <ContentWrapper>
    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
      {content}
    </ReactMarkdown>
  </ContentWrapper>
);
