import { UnstyledButton } from 'components-library/button/styled';
import styled, { css } from 'styled-components';
import { Styles } from 'styles';
import { fadeIn } from 'utils/keyframes';

const KeyboardKeyStyle = css`
  display: flex;
  align-items: center;
  background: transparent;
  background-image: linear-gradient(-225deg, rgb(213, 219, 228), rgb(248, 248, 248));
  color: #555 !important;
  border-radius: 3px;
  box-shadow: rgb(205, 205, 230) 0px -2px 0px 0px inset, rgb(255, 255, 255) 0px 0px 1px 1px inset,
    rgba(30, 35, 90, 0.4) 0px 1px 2px 1px;
  height: 18px;
  justify-content: center;
  padding-bottom: 2px;
  position: relative;
  top: -1px;
  width: 20px;
`;

export const SearchIconWrapper = styled.div`
  transition: 0.2s;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${Styles.borderRadius};
  color: #0007;
  background: #00000017;
  margin-left: 4px;
  /* ${KeyboardKeyStyle} */
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 8px;
  pointer-events: none;
  color: ${Styles.color.text};
`;

export const ClearSearchBtn = styled(UnstyledButton)`
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 8px;

  :hover {
    ${SearchIconWrapper} {
      background: #00000025;
    }
  }
`;

export const InputWrapper = styled.input<{
  textarea?: boolean;
  isCopiable?: boolean;
  hasError?: boolean;
  $width?: boolean;
  isNotEditable?: boolean;
}>`
  background-color: ${Styles.color.white}06;
  border-color: ${Styles.color.white}33;
  border-radius: ${Styles.borderRadius};
  border-style: solid;
  border-width: ${Styles.borderWidth};
  box-sizing: border-box;
  color: ${Styles.color.text};
  font-size: 16px;
  height: 38px;
  outline: none;
  padding: 8px;
  transition: 0.2s;
  white-space: pre;
  width: fill-available;

  ${(p) =>
    p.hasError &&
    css`
      border-color: ${Styles.color.danger};
    `}

  :focus {
    border-color: ${Styles.color.primary};
  }

  ${(p) => p.$width && `width: ${p.$width};`}

  ${(p) =>
    p.textarea &&
    css`
      resize: none;
      height: unset;
      white-space: unset;
      overflow: scroll;
    `}

  ::placeholder {
    color: ${Styles.color.white}66;
    opacity: 1; /* Firefox */
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
`;

export const Label = styled.label`
  display: inline-block;
  margin-bottom: 4px;
`;

export const PositionRelativeWrapper = styled.div<{
  hasError?: boolean;
  $hasBottomMargin: boolean;
}>`
  position: relative;
  ${(p) => !p.hasError && p.$hasBottomMargin && 'margin-bottom: 12px;'}
`;

export const ErrorMessage = styled.div`
  animation: 0.2s ${fadeIn};
  color: ${Styles.color.danger};
  font-size: 14px;
  margin: 4px 0 12px;
`;
