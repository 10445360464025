import { UnStyledLink } from 'components-library/button/styled';
import * as React from 'react';
import { FC } from 'react';

import { Icon } from '../icon';
import { ANIMATION_TIME, AnimationDot, ButtonWrapper, LoadingWrapper } from './styled';
import { IButtonProps } from './types';

function LoadingAnimation({ secondary = false }) {
  return (
    <LoadingWrapper>
      <AnimationDot secondary={secondary} delay={0} />
      <AnimationDot secondary={secondary} delay={ANIMATION_TIME / 3} />
      <AnimationDot secondary={secondary} delay={(ANIMATION_TIME / 3) * 2} />
    </LoadingWrapper>
  );
}
//
/**
 * Button description:
 * The art of trigerring an action!
 */
export const Button: FC<IButtonProps> = ({
  className,
  onClick,
  children,
  loading,
  disabled,
  danger,
  textButton,
  rightIcon,
  leftIcon,
  ref,
  ...otherProps
}) => {
  const button = (
    <ButtonWrapper
      {...otherProps}
      onClick={onClick}
      className={`${className}`}
      $fullWidth={otherProps.fullWidth}
      disabled={disabled || loading}
      $loading={loading}
      danger={danger}
      $textButton={textButton}
      $hasAnIcon={!!(leftIcon || rightIcon)}
      ref={ref}>
      {loading && <LoadingAnimation secondary={otherProps.secondary} />}
      <>
        {leftIcon && <Icon name={leftIcon} style={{ paddingRight: '8px' }} />}
        {children}
        {rightIcon && <Icon name={rightIcon} style={{ paddingLeft: '9px' }} />}
      </>
    </ButtonWrapper>
  );
  // Wrap a Link over it if it's a link
  if (otherProps.to) {
    return <UnStyledLink to={otherProps.to}>{button}</UnStyledLink>;
  }
  if (otherProps.href) {
    return (
      <UnStyledLink as="a" href={otherProps.href} target="_blank">
        {button}
      </UnStyledLink>
    );
  }
  return button;
};
