import { Button } from 'components-library/button';
import { AuthButton } from 'components/auth-button';
import { useJobs } from 'hooks/jobs';
import { useRessources } from 'hooks/ressources';
import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getMenuItems } from './menu-items';
import {
  AppLayoutWrapper,
  TopNav,
  Logo,
  MenuLink,
  MenuAndChild,
  MenuItemsWrapper,
  Emoji,
  NumOfRessources,
  ChildrenWrapper,
  DesktopMenuItems,
  MobileMenuBtn,
  MobileMenuWrapper,
  CloseBtnWrapper,
  MobileMenuBtnsWrapper,
  MenuItemsLinksWrapper
} from './styled';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const MenuItemsLinks = ({ onClick = () => {} }) => {
  const { pathname: currentPath } = useLocation();

  const { ressourcesDict } = useRessources();
  const { jobs } = useJobs();

  const jobsMenuItem = {
    emoji: '💼',
    name: 'Jobs',
    path: '/jobs',
    numOfResources: jobs?.length
  };

  return (
    <MenuItemsLinksWrapper>
      {[...getMenuItems(ressourcesDict), jobsMenuItem].map(
        ({ name, emoji, path, numOfResources }) => (
          <MenuLink key={path} to={path} $isActive={currentPath === path} onClick={onClick}>
            <Emoji>{emoji}</Emoji>
            <span>{name}</span>
            {numOfResources !== undefined && <NumOfRessources>({numOfResources})</NumOfRessources>}
          </MenuLink>
        )
      )}
      <MenuLink to="/new/category" $isActive={currentPath === '/new/category'} onClick={onClick}>
        <Emoji>➕</Emoji>
        <span>Submit new category</span>
      </MenuLink>
    </MenuItemsLinksWrapper>
  );
};

const MenuItems = () => {
  return (
    <MenuItemsWrapper>
      <MenuItemsLinks />
    </MenuItemsWrapper>
  );
};

const MobileMenu = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <>
      <Button leftIcon="menu" onClick={() => setShowMobileMenu(true)}>
        Menu
      </Button>
      {showMobileMenu && (
        <MobileMenuWrapper>
          <CloseBtnWrapper>
            <Button leftIcon="close" onClick={() => setShowMobileMenu(false)}>
              Close
            </Button>
          </CloseBtnWrapper>

          <MenuItemsLinks onClick={() => setShowMobileMenu(false)} />

          <MobileMenuBtnsWrapper>
            <Button
              to="/new/job"
              style={{ marginRight: '20px' }}
              onClick={() => setShowMobileMenu(false)}>
              Post a job
            </Button>
          </MobileMenuBtnsWrapper>

          <MobileMenuBtnsWrapper>
            <AuthButton onClick={() => setShowMobileMenu(false)} />
          </MobileMenuBtnsWrapper>
        </MobileMenuWrapper>
      )}
    </>
  );
};

export const AppLayout: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <AppLayoutWrapper>
      <TopNav>
        <Logo to="/">🧰 web3-tools</Logo>

        <DesktopMenuItems>
          <Button to="/new/job" style={{ marginRight: '20px' }}>
            Post a job
          </Button>
          <AuthButton />
        </DesktopMenuItems>

        <MobileMenuBtn>
          <MobileMenu />
        </MobileMenuBtn>
      </TopNav>

      <MenuAndChild>
        <MenuItems />
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </MenuAndChild>
    </AppLayoutWrapper>
  );
};
