import { message } from 'components-library/message';
import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Styles } from 'styles';
import { useMutation } from '@apollo/client';
import { VOTE_FOR_RESSOURCE } from 'utils/gql';
import { Icon } from 'components-library/icon';
import { useAccount } from 'wagmi';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';

export const IconWrapper = styled.div`
  opacity: 0;
  font-size: 14px;
  transition: 0.2s;
  margin-left: 4px;
  color: ${Styles.color.white}88;
`;

export const CardWrapper = styled.a`
  border-radius: ${Styles.borderRadius};
  padding: 12px;
  border: ${Styles.borderWidth} solid transparent;
  background: #ffffff11;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  :hover {
    ${IconWrapper} {
      opacity: 1;
      margin-left: 8px;
    }
  }
`;

const TitleAndUpvote = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const VoteBtn = styled.button<{ hasVote?: boolean }>`
  min-width: auto;
  padding: 4px;
  height: auto;
  cursor: pointer;
  border: 2px solid ${Styles.color.white}44;
  border-radius: ${Styles.borderRadius};
  background: transparent;
  color: ${Styles.color.white};
  font-size: 16px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  :hover {
    border-color: ${Styles.color.primary};
  }

  ${(p) =>
    p.hasVote &&
    css`
      border-color: ${Styles.color.primary};
      color: ${Styles.color.primary};
      background-color: ${Styles.color.primary}11;
    `}
`;

const Title = styled.a`
  margin-top: 0;
  font-size: 24px;
  text-decoration: underline;
  color: ${Styles.color.white};
  margin-bottom: 20px;
  font-weight: ${Styles.font.weight.bold};
  cursor: pointer;
  margin: 3px 0 0;
  text-decoration-color: ${Styles.color.white}88;
  transition: 0.2s;
  display: flex;
  align-items: center;

  :hover {
    text-decoration-color: ${Styles.color.primary};

    ${IconWrapper} {
      opacity: 1;
      color: ${Styles.color.primary};
    }
  }
`;

const Description = styled.p`
  margin-bottom: 0;
`;

const VoteWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VoteCount = styled.div`
  padding: 0 2px;
  min-width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CreatedAt = styled.div`
  margin-top: 12px;
  text-align: right;
  opacity: 0.6;
`;

const StyledLink = styled(Link)`
  border-radius: ${Styles.borderRadius};
  color: ${Styles.color.white};
  text-decoration-color: ${Styles.color.white}88;
  :hover {
    text-decoration-color: ${Styles.color.primary};
  }
`;

const CategoryWrapper = styled.div`
  text-align: left;
  padding-top: 12px;
`;

export const RessourceCard = ({
  description,
  title,
  upvotes,
  url,
  downvotes,
  id,
  refetch,
  createdAt,
  category,
  showCategory,
  showUrl
}) => {
  const { data: account } = useAccount();
  const accountAddress = account?.address;
  const isConnected = !!accountAddress;

  const [voteForRessourceMutation, { loading: voteForRessourceIsLoading }] =
    useMutation(VOTE_FOR_RESSOURCE);

  const handleUpvoteClick = useCallback(
    async (isUpvoteClick) => {
      if (!isConnected) {
        message.error('You need to be connected in order to vote.');
        return;
      }

      // Sending the encrypted wallet ID to see if it fit with the one coming from the server
      const encryptedWalletId = CryptoJS.AES.encrypt(
        accountAddress,
        process.env.REACT_APP_VOTE_ENCRYPTION_KEY
      ).toString();

      await voteForRessourceMutation({
        variables: { ressourceId: id, isUpvoteClick, userId: encryptedWalletId }
      });
      // Refetch updated data
      refetch();
      message.success('Your vote has been updated.');
    },
    [id, isConnected, refetch, voteForRessourceMutation]
  );

  const userHasUpvote = upvotes.includes(accountAddress);
  const userHasDownvote = downvotes.includes(accountAddress);

  return (
    <CardWrapper>
      <div>
        <TitleAndUpvote>
          <Title href={url} target="_blank">
            {title}{' '}
            <IconWrapper>
              <Icon name="launch" />
            </IconWrapper>
          </Title>

          <VoteWrapper>
            <VoteBtn type="button" onClick={() => handleUpvoteClick(true)} hasVote={userHasUpvote}>
              <Icon name="expand_less" />
            </VoteBtn>
            <VoteCount>{upvotes.length - downvotes.length}</VoteCount>
            <VoteBtn
              type="button"
              onClick={() => handleUpvoteClick(false)}
              hasVote={userHasDownvote}>
              <Icon name="expand_more" />
            </VoteBtn>
          </VoteWrapper>
        </TitleAndUpvote>
        <Description>{description}</Description>

        {showCategory && (
          <CategoryWrapper>
            <span style={{ opacity: '0.6' }}>Category:</span>{' '}
            <StyledLink to={`/resources/${category}`}>{category.replaceAll('-', ' ')}</StyledLink>
          </CategoryWrapper>
        )}

        {showUrl && (
          <CategoryWrapper>
            <span style={{ opacity: '0.6' }}>URL:</span>{' '}
            <StyledLink as="a" href={url} target="_blank">
              {url}
            </StyledLink>
          </CategoryWrapper>
        )}
      </div>

      <CreatedAt>Added {moment(Number(createdAt)).fromNow()}</CreatedAt>
    </CardWrapper>
  );
};
