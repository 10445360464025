import { gql } from '@apollo/client';

// Queries
export const GET_RESSOURCES = gql`
  query GetRessources {
    getRessources {
      ressources {
        title
        url
        description
        category
        _id
        upvotes
        downvotes
        isPending
        createdAt
      }
    }
  }
`;

export const GET_JOBS = gql`
  query getJobs {
    getJobs {
      jobs {
        position
        description
        companyName
        logoUrl
        location
        applyUrl
        email
        tag1
        tag2
        tag3
        tag4
        promotionalCode
        minSalary
        maxSalary
        createdAt
        createdBy
        _id
      }
    }
  }
`;

// Mutations
export const ADD_RESSOURCE = gql`
  mutation AddRessource($title: String!, $url: String!, $description: String!, $category: String!) {
    addRessource(title: $title, url: $url, description: $description, category: $category) {
      ressource {
        title
      }
    }
  }
`;

export const VOTE_FOR_RESSOURCE = gql`
  mutation VoteForRessource($ressourceId: String!, $isUpvoteClick: Boolean!, $userId: String!) {
    voteForRessource(ressourceId: $ressourceId, isUpvoteClick: $isUpvoteClick, userId: $userId) {
      msg
    }
  }
`;

export const ADD_JOB = gql`
  mutation AddJob(
    $transactionHash: String!
    $position: String!
    $description: String!
    $companyName: String!
    $logoUrl: String
    $location: String!
    $applyUrl: String!
    $tag1: String
    $tag2: String
    $tag3: String
    $tag4: String
    $minSalary: String
    $maxSalary: String
    $email: String!
  ) {
    addJob(
      transactionHash: $transactionHash
      position: $position
      description: $description
      companyName: $companyName
      logoUrl: $logoUrl
      location: $location
      applyUrl: $applyUrl
      tag1: $tag1
      tag2: $tag2
      tag3: $tag3
      tag4: $tag4
      minSalary: $minSalary
      maxSalary: $maxSalary
      email: $email
    ) {
      job {
        _id
      }
    }
  }
`;

export const ADD_TO_NEWSLETTER = gql`
  mutation AddToNewsletter($email: String!) {
    addToNewsletter(email: $email) {
      status
    }
  }
`;

export const VALIDATE_PROMO_CODE = gql`
  mutation ValidatePromoCode($promoCode: String!) {
    validatePromoCode(promoCode: $promoCode) {
      discount
    }
  }
`;

export const SEARCH_RESSOURCES = gql`
  mutation SearchRessources($searchString: String!) {
    searchRessources(searchString: $searchString) {
      ressources {
        title
        url
        description
        category
        _id
        upvotes
        downvotes
        isPending
        createdAt
      }
    }
  }
`;
