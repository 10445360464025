import styled from 'styled-components';
import { Styles } from 'styles';

export const Form = styled.form`
  max-width: 800px;
  margin: 0 auto;
`;

export const HR = styled.div`
  border-bottom: 2px solid ${Styles.color.white}44;
  margin: 20px 0;
`;

export const Tags = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
`;

export const Price = styled.span`
  color: ${Styles.color.primary};
`;

export const AddCategoryWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;
