import styled from 'styled-components';
import { Styles } from 'styles';

export const SelectWrapper = styled.div<{ hasError?: boolean; optionIsDisabled?: boolean }>`
  ${(p) => !p.hasError && ' margin-bottom: 16px;'}

  select {
    background-color: ${Styles.color.text}06;
    border-radius: 5px;
    font-size: 16px;
    padding: 8px 12px;
    appearance: none;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    height: 38px;
    color: ${Styles.color.text};
    min-width: 180px;

    &:focus,
    &:active {
      outline: none;
      border-color: ${Styles.color.primary};
    }
    border: ${Styles.borderWidth} solid ${Styles.color.text}33;

    ${(p) => p.hasError && `border-color: ${Styles.color.danger};`}

    ${(p) => p.optionIsDisabled && `color: ${Styles.color.white}66`}
  }
`;

export const Label = styled.div`
  margin-bottom: 4px;
  font-weight: bold;
`;

export const SelectInnerWrapper = styled.div`
  position: relative;
`;

export const ChevronWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  pointer-events: none;
  color: ${Styles.color.text}44;
`;
export const TopMargin = styled.div`
  height: 4px;
`;
