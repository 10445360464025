import { Button } from 'components-library/button';
import { useAccount } from 'wagmi';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const AuthButton = ({ onClick = () => {} }) => {
  const { data: account } = useAccount();

  return (
    <Button onClick={onClick} to="/user">
      {account ? 'User' : 'Connect with wallet'}
    </Button>
  );
};
