import { useCallback, useState } from 'react';
import { Loader } from 'components-library/loader';
import { JOBS_TAGS } from 'components/new-form-router/job-form';
import { useJobs } from 'hooks/jobs';
import { JobCard } from './job-card';
import styled, { css } from 'styled-components';
import { Styles } from 'styles';
import { NewsletterForm } from 'components/newsletter-form';
import { Footer } from 'components/footer';

const TagButton = styled.button<{ isActive: boolean }>`
  cursor: pointer;
  border: 2px solid ${Styles.color.white}44;
  border-radius: ${Styles.borderRadius};
  background: transparent;
  color: ${Styles.color.white};
  margin: 4px 4px;
  transition: 0.2s;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 14px;

  :hover {
    border-color: ${Styles.color.primary};
  }

  ${(p) =>
    p.isActive &&
    css`
      border-color: ${Styles.color.primary};
      color: ${Styles.color.primary};
      background-color: ${Styles.color.primary}11;
    `}
`;

const TagsWrapper = styled.div`
  margin: 0 -4px 20px;
`;

export const JobsListing = () => {
  const [activeTags, setActiveTag] = useState<string[]>([]);
  const { jobs, loading } = useJobs();

  const handleToggleTag = useCallback(
    (tag) => {
      const activeTagsCopy = [...activeTags];
      if (!activeTagsCopy.includes(tag)) {
        activeTagsCopy.push(tag);
      } else {
        activeTagsCopy.splice(activeTagsCopy.indexOf(tag), 1);
      }
      setActiveTag(activeTagsCopy);
      // TODO: Save the tags inside the localstorage???
    },
    [activeTags]
  );

  let displayedJobs = jobs ? [...jobs] : [];
  // Filter the tags if there are
  if (activeTags.length) {
    displayedJobs = displayedJobs?.filter(({ tag1, tag2, tag3, tag4 }) =>
      [tag1, tag2, tag3, tag4].some((tag) => activeTags.includes(tag))
    );
  }

  return (
    <div style={{ marginBottom: '20px' }}>
      <h1 style={{ marginTop: '0' }}>Jobs</h1>

      <p>Find the latest Web3 ecosystem jobs.</p>
      <TagsWrapper>
        {JOBS_TAGS.map((tag) => (
          <TagButton
            key={tag}
            isActive={activeTags.includes(tag)}
            onClick={() => handleToggleTag(tag)}>
            {tag}
          </TagButton>
        ))}
      </TagsWrapper>

      {!loading && (
        <>
          {displayedJobs?.map(
            ({ position, tag1, tag2, tag3, tag4, companyName, location, _id, logoUrl }) => (
              <JobCard
                key={_id}
                position={position}
                tags={[tag1, tag2, tag3, tag4]}
                company={companyName}
                location={location}
                id={_id}
                logoUrl={logoUrl}
                activeTags={activeTags}
              />
            )
          )}

          {displayedJobs?.length === 0 && <p>There is no result for your search.</p>}

          <NewsletterForm />

          <Footer />
        </>
      )}

      {loading && <Loader />}
    </div>
  );
};
