import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Styles } from 'styles';
import { flashingAnimation } from 'utils/keyframes';

interface IButtonWrapper {
  className?: string;
  disabled?: boolean;
  secondary?: boolean;
  $fullWidth?: boolean;
  $loading?: boolean;
  danger?: boolean;
  $textButton?: boolean;
  type?: 'button' | 'submit' | 'reset';
  $hasAnIcon?: boolean;
}

export const buttonBaseStyles = css`
  position: relative;
  align-items: center;
  border-radius: ${Styles.borderRadius};
  border-style: solid;
  border-width: ${Styles.borderWidth};
  border-color: ${Styles.color.primary};
  cursor: pointer;
  display: inline-flex;
  font-size: 18px;
  font-weight: ${Styles.font.weight.bold};
  height: 38px;
  justify-content: center;
  line-height: 1;
  min-width: 120px;
  padding: 0px 16px;
  transition: 0.2s all, 0s color;
  white-space: nowrap;
  color: ${Styles.color.black};
  background-color: ${Styles.color.primary};
  text-decoration: none;
  font-weight: bold;

  ::after {
    content: '';
    display: block;
    box-sizing: border-box;
    background: ${Styles.color.primary}44;
    border: ${Styles.borderWidth} solid ${Styles.color.primary}66;
    border-radius: ${Styles.borderRadius};
    height: calc(100% + 6px);
    width: calc(100% + 6px);
    position: absolute;
    top: 3px;
    left: 3px;
    right: 0;
    z-index: -2;
    transition: transform 0.2s;
  }

  :hover {
    transform: translate(2px, 2px);

    ::after {
      transform: translate(-2px, -2px);
    }
  }

  :active {
    transform: translate(4px, 4px);

    ::after {
      transform: translate(-4px, -4px);
    }
  }
`;

export const ButtonWrapper = styled.button<IButtonWrapper>`
  ${buttonBaseStyles}

  :disabled {
    ${(p) =>
      !p.$loading &&
      css`
        background: #ccc;
        border-color: #ccc;
        color: ${Styles.color.black}88;

        ::after {
          background: #cccccc44;
          border: ${Styles.borderWidth} solid #cccccc66;
        }
      `}

    :hover,
    :active {
      transform: none;
      ::after {
        transform: none;
      }
    }
  }

  ${(p) =>
    p.$loading &&
    css`
      color: transparent !important;
    `}

  ${(p) =>
    p.danger &&
    css`
      border-color: ${Styles.color.danger};
      background-color: ${Styles.color.danger};
      color: ${Styles.color.white};

      ::after {
        background: ${Styles.color.danger}44;
        border: ${Styles.borderWidth} solid ${Styles.color.danger}66;
      }
    `}
`;

export const UnstyledButton = styled.button<{ hasAnimation?: boolean }>`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  font-size: inherit;
`;

export const ANIMATION_TIME = 1;

export const AnimationDot = styled.div<{ delay: number; secondary?: boolean }>`
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${Styles.color.black};
  margin: 4px;
  animation: ${ANIMATION_TIME}s ${flashingAnimation} infinite cubic-bezier(0.22, 0.61, 0.36, 1);
  animation-delay: ${(p) => p.delay}s;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UnStyledLink = styled(Link)`
  text-decoration: none;
`;
