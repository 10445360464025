/* eslint-disable max-len */
import { RessourceCard } from 'components/ressource-card';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'components-library/button';
import { AppLayoutWrapper } from 'components/app-layout/styled';
import { Loader } from 'components-library/loader';
import { useRessources } from 'hooks/ressources';
import Select from 'components-library/select';
import { useCallback, useState } from 'react';
import { useAccount } from 'wagmi';
import { message } from 'components-library/message';

export const categoryDescription = {
  'pending-resources':
    'Each newly added resources has 7 days to get a total of 5 absolute votes (upvotes - downvotes) in order to be added permanently to the platform.',
  'recently-added': 'Ressources permanently added in the last 7 days.',
  'smart-contract-languages': 'This is the list of the smart contract languages ressources.',
  deployment: 'This is the list of the deplpoyment ressources.',
  wallets: 'This is the list of the wallets ressources.',
  'block-explorers': 'This is the list of the block explorers ressources.',
  'layer-1-Connection': 'This is the list of the Layer 1 connection ressources.',
  'front-end-tools': 'This is the list of the front end tools ressources.',
  'security-analysis': 'This is the list of the security analysis ressources.',
  monitoring: 'This is the list of the monitoring ressources.',
  storage: 'This is the list of the storage ressources.',
  others: 'This is the list of the others ressources.',
  all: 'All the ressources permanently added to the platform.'
};

const PageTitle = styled.h1`
  margin-top: 0;
  text-transform: capitalize;
`;

export const RessourceCardListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-bottom: 80px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const StaticButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 0;
  left: 0;
`;

const RightAlignButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SortByWrapper = styled.div`
  /* width: 200px; */
  display: flex;
  justify-content: flex-end;
`;

export const BottomStaticButtonWrapper = ({ children }) => (
  <StaticButtonWrapper>
    <AppLayoutWrapper>
      <RightAlignButtonWrapper>{children}</RightAlignButtonWrapper>
    </AppLayoutWrapper>
  </StaticButtonWrapper>
);

const sortByOptions = [
  {
    label: 'Most relevant',
    value: 'most-relevant'
  },
  {
    label: 'Recently added',
    value: 'recently-added'
  }
];

const sortByAbsoluteVotes = (displayedRessources) =>
  displayedRessources?.sort((a, b) => {
    const ressourceAbsoluteVotesA = a.upvotes.length - a.downvotes.length;
    const ressourceAbsoluteVotesB = b.upvotes.length - b.downvotes.length;
    return ressourceAbsoluteVotesA > ressourceAbsoluteVotesB ? -1 : 1;
  });

const sortByAbsoluteRecentlyAdded = (displayedRessources) =>
  displayedRessources?.sort((a, b) => {
    const dateA = new Date(Number(a.createdAt)).getTime();
    const dateB = new Date(Number(b.createdAt)).getTime();
    return dateA > dateB ? -1 : 1;
  });

export const RessourceCardList = () => {
  const { pathname } = useLocation();
  const { loading, refetch, ressourcesDict } = useRessources();
  const { data: account } = useAccount();
  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState(sortByOptions[0].value);

  const pageTitle = pathname.replace('/resources', '').replaceAll('/', '').replaceAll('-', ' ');

  const handleSortByChange = useCallback((e) => {
    setSortBy(e.target.value);
  }, []);

  // Filter the ressources per categories
  const currentCategory = pathname.replace('/resources', '').replaceAll('/', '');

  let displayedRessources = ressourcesDict[currentCategory];

  // Don't add it to the params if they're not a real ressources category
  const nonValidCategory = ['pending-resources', 'recently-added', 'all'];
  const showCategorySearchParams = !nonValidCategory.includes(currentCategory);
  const addNewRessourcesLink = showCategorySearchParams
    ? `/new/ressource?category=${currentCategory}`
    : '/new/ressource';

  if (sortBy === 'recently-added') {
    displayedRessources = sortByAbsoluteRecentlyAdded(displayedRessources);
  } else if (sortBy === 'most-relevant') {
    displayedRessources = sortByAbsoluteVotes(displayedRessources);
  }

  const handlePostNewRessourceClick = useCallback(
    (e) => {
      if (!account) {
        message.error('You need to be connected first.');
        navigate('/user');
        return;
      }
      navigate(addNewRessourcesLink);
    },
    [account, addNewRessourcesLink, navigate]
  );

  return (
    <div>
      <PageTitle>{pageTitle}</PageTitle>

      <p>{categoryDescription[currentCategory]}</p>

      {!loading && (
        <>
          <SortByWrapper>
            <Select
              id="sortBy"
              placeholder="Sort by:"
              options={sortByOptions}
              onChange={handleSortByChange}
              name="sortBy"
              value={sortBy}
            />
          </SortByWrapper>

          <RessourceCardListWrapper>
            {displayedRessources?.map(
              ({ description, title, upvotes, _id, url, downvotes, createdAt, category }) => (
                <RessourceCard
                  key={_id}
                  description={description}
                  title={title}
                  upvotes={upvotes}
                  downvotes={downvotes}
                  url={url}
                  id={_id}
                  refetch={refetch}
                  createdAt={createdAt}
                  category={category}
                  showCategory={!showCategorySearchParams}
                  showUrl={currentCategory === 'pending-resources'}
                />
              )
            )}
            {!displayedRessources?.length && <p>Nothing there for the moment.</p>}
          </RessourceCardListWrapper>
        </>
      )}

      {loading && <Loader />}

      <BottomStaticButtonWrapper>
        {<Button onClick={handlePostNewRessourceClick}>Post new resource</Button>}
      </BottomStaticButtonWrapper>
    </div>
  );
};
