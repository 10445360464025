import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { IBaseProps } from 'types';
import { useQuery } from '@apollo/client';
import { GET_RESSOURCES } from 'utils/gql';
import { getNumRessourcesPerCategory } from './get-num-ressources-per-category';

interface IRessourcesContext {
  loading: boolean;
  ressources: [any];
  refetch: () => void;
  ressourcesDict: any;
}

export const RessourcesContext = createContext<IRessourcesContext>({} as any as IRessourcesContext);

export const RessourcesProvider: React.FC<IBaseProps> = ({ children }) => {
  const [ressourcesDict, setRessourcesDict] = useState({});

  const { loading, data, refetch } = useQuery(GET_RESSOURCES);

  const ressources = data?.getRessources?.ressources;

  useEffect(() => {
    setRessourcesDict(getNumRessourcesPerCategory(ressources));
  }, [ressources]);

  const getCtx = useCallback(() => {
    return {
      loading: loading,
      ressources,
      refetch,
      ressourcesDict
    };
  }, [loading, refetch, ressources, ressourcesDict]);

  return <RessourcesContext.Provider value={getCtx()}>{children}</RessourcesContext.Provider>;
};

export const useRessources = () => {
  return useContext(RessourcesContext);
};

export default RessourcesContext.Consumer;
