/* eslint-disable max-len */
import styled from 'styled-components';

const ProductHuntButtonWrapper = styled.div`
  position: fixed;
  bottom: 8px;
  right: 8px;
  width: 250px;
  margin: 0 auto;
`;

const ProductHuntBtnLink = styled.a`
  display: flex;
`;

const ProductHuntFooterBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 20px;
`;

export const ProductHuntBtnOnly = () => (
  <ProductHuntFooterBtn>
    <ProductHuntBtnLink
      href="https://www.producthunt.com/posts/web3-tools?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-web3&#0045;tools"
      target="_blank"
      rel="noreferrer">
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=345472&theme=light"
        alt="web3&#0032;tools - The&#0032;first&#0032;community&#0045;led&#0032;web3&#0032;developer&#0032;resources&#0032;platform | Product Hunt"
        style={{ height: '54px', width: '250px' }}
      />
    </ProductHuntBtnLink>
  </ProductHuntFooterBtn>
);

export const ProductHuntButton = () => (
  <ProductHuntButtonWrapper>
    <ProductHuntBtnLink
      href="https://www.producthunt.com/posts/web3-tools?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-web3&#0045;tools"
      target="_blank"
      rel="noreferrer">
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=345472&theme=light"
        alt="web3&#0032;tools - The&#0032;first&#0032;community&#0045;led&#0032;web3&#0032;developer&#0032;resources&#0032;platform | Product Hunt"
        style={{ height: '54px', width: '250px' }}
      />
    </ProductHuntBtnLink>
  </ProductHuntButtonWrapper>
);
