import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Styles } from 'styles';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LOADER_SIZE = '40px';
const LOADER_WIDTH = '4px';
const LOADER_COLOR = Styles.color.primary;

const LoaderDiv = styled.div`
  border: ${LOADER_WIDTH} solid transparent;
  border-top: ${LOADER_WIDTH} solid ${LOADER_COLOR};
  border-bottom: ${LOADER_WIDTH} solid ${LOADER_COLOR};
  border-right: ${LOADER_WIDTH} solid ${LOADER_COLOR};
  border-radius: 50%;
  width: ${LOADER_SIZE};
  height: ${LOADER_SIZE};
  animation: ${spin} 1.2s linear infinite;
}`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 140px;
  pointer-events: none;
`;

export const Loader = () => (
  <LoaderWrapper>
    <LoaderDiv />
  </LoaderWrapper>
);
