import moment from 'moment';

export const getNumRessourcesPerCategory = (ressources) => {
  const ressourcesDict: any = {
    'pending-resources': [],
    'recently-added': [],
    'smart-contract-languages': [],
    deployment: [],
    wallets: [],
    'block-explorers': [],
    'layer-1-connection': [],
    'front-end-tools': [],
    'security-analysis': [],
    monitoring: [],
    storage: [],
    others: [],
    all: []
  };

  const dateNow = moment();
  const SEVEN_DAYS_FROM_NOW_IN_HOURS = 7 * 24;

  //
  ressources?.forEach((ress) => {
    // Filter all categories
    if (!ress.isPending) {
      // Add it to it's own category
      ressourcesDict[ress.category] = [...ressourcesDict[ress.category], ress];
      // Add it to the all category
      ressourcesDict.all = [...ressourcesDict.all, ress];

      // Add it to the recently added if it's not pending and it has been created less than 5 days ago
      const createdAtDate = moment(Number(ress.createdAt));
      const diffHours = dateNow.diff(createdAtDate, 'hours');
      if (diffHours <= SEVEN_DAYS_FROM_NOW_IN_HOURS) {
        ressourcesDict['recently-added'] = [...ressourcesDict['recently-added'], ress];
      }
    }
    // Get the pending items
    if (ress.isPending) {
      ressourcesDict['pending-resources'] = [...ressourcesDict['pending-resources'], ress];
    }
  });
  return ressourcesDict;
};
