import { Button } from 'components-library/button';
import { useConnect } from 'wagmi';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Styles } from 'styles';
import metamaskWalletIcon from 'assets/metamaskWalletIcon.png';
import coinbaseWalletIcon from 'assets/coinbaseWalletIcon.svg';
import walletConnectIcon from 'assets/walletConnectIcon.svg';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  margin: 40px auto;
`;

const ConnectionBtn = styled(Button)`
  margin-bottom: 20px;
  background: ${Styles.color.white};
  border-color: ${Styles.color.white};

  justify-content: space-between;

  ::after {
    background: ${Styles.color.white}44;
    border: ${Styles.borderWidth} solid ${Styles.color.white}66;
  }
`;

const StyledLink = styled(Link)`
  color: ${Styles.color.primary};
`;

const Img = styled.img`
  height: 20px;
  width: 20px;
`;

const mapWalletNameToImg = {
  MetaMask: metamaskWalletIcon,
  'Coinbase Wallet': coinbaseWalletIcon,
  WalletConnect: walletConnectIcon
};
export const ConnectBtns = () => {
  const { connect, connectors, error, isConnecting, pendingConnector } = useConnect();

  return (
    <ButtonsWrapper>
      <h1>Connect with wallet</h1>

      <p style={{ marginBottom: '40px' }}>
        By connecting a wallet, you agree to Web3 developer ressources{' '}
        <StyledLink to="/terms-of-service">Terms of Service</StyledLink>.
      </p>

      {connectors.map((connector) => (
        <ConnectionBtn
          fullWidth
          disabled={!connector.ready}
          key={connector.id}
          loading={isConnecting && connector.id === pendingConnector?.id}
          onClick={() => connect(connector)}>
          <span>
            {connector.name}
            {!connector.ready && ' (unsupported)'}
          </span>
          {<Img src={mapWalletNameToImg[connector.name]} />}
        </ConnectionBtn>
      ))}

      {error && <div>{error.message}</div>}
    </ButtonsWrapper>
  );
};
