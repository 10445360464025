import { useMutation } from '@apollo/client';
import { Button } from 'components-library/button';
import { Input } from 'components-library/input';
import { message } from 'components-library/message';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ADD_TO_NEWSLETTER } from 'utils/gql';
import { Logger } from 'utils/logger';

const NewsletterFormWrapper = styled.div`
  margin: 80px auto;
  max-width: 600px;
`;

const FormWrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 12px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const Header = styled.h3`
  font-size: 24px;
  margin: 0;
`;

const Par = styled.p`
  opacity: 0.6;
`;

export const NewsletterForm = () => {
  const [email, setEmail] = useState('');

  const [addToNewsletterMutation, { loading: isLoading }] = useMutation(ADD_TO_NEWSLETTER);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        addToNewsletterMutation({ variables: { email } });
        message.success('You have been added to the newletter!');
        setEmail('');
      } catch (err) {
        Logger.error(err);
        message.error();
      }
    },
    [addToNewsletterMutation, email]
  );

  return (
    <NewsletterFormWrapper>
      <Header>📮 Newsletter</Header>
      <Par>
        Sign up to the web3-tools newsletter to stay up to date with the latest features, resources
        added and job posts.
      </Par>
      <FormWrapper onSubmit={handleSubmit}>
        <Input
          value={email}
          type="email"
          placeholder="Type your email"
          onChange={(e) => setEmail(e.target.value)}
          name="email"
          required
        />
        <Button type="submit" loading={isLoading}>
          Subscribe
        </Button>
      </FormWrapper>
    </NewsletterFormWrapper>
  );
};
