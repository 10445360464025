import * as React from 'react';
import { FC } from 'react';
import { ISelectProps } from './types';
import { SelectWrapper, SelectInnerWrapper, ChevronWrapper, TopMargin } from './styled';
import { ErrorMessage, Label } from '../input/styled';
import { Icon } from 'components-library/icon';

const Select: FC<ISelectProps> = ({
  id,
  label,
  options,
  onChange,
  value,
  name,
  placeholder,
  error
}) => {
  const selectOptions = [{ disabled: true, label: placeholder ?? label, value: '' }, ...options];

  const optionIsDisabled = selectOptions.find((option) => option.value === value)?.disabled;

  return (
    <div>
      <SelectWrapper hasError={!!error} optionIsDisabled={optionIsDisabled}>
        {label && (
          <>
            <Label htmlFor={id}>{label}</Label>
            <TopMargin />
          </>
        )}

        <SelectInnerWrapper>
          <select id={id} onChange={onChange} value={value} name={name}>
            {selectOptions.map(
              ({ value: optionValue, label: optionLabel, disabled: optionDisabled }) => (
                <option
                  value={optionValue}
                  key={optionValue}
                  disabled={!optionValue || optionDisabled}>
                  {optionLabel}
                </option>
              )
            )}
          </select>

          <ChevronWrapper>
            <Icon name="expand_more" />
          </ChevronWrapper>
        </SelectInnerWrapper>
      </SelectWrapper>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

export default Select;
