import { createContext, useCallback, useContext } from 'react';
import * as React from 'react';
import { IBaseProps } from 'types';
import { useQuery } from '@apollo/client';
import { GET_JOBS } from 'utils/gql';

interface IJobsContext {
  loading: boolean;
  jobs: any;
  refetch: () => void;
}

export const JobsContext = createContext<IJobsContext>({} as any as IJobsContext);

export const JobsProvider: React.FC<IBaseProps> = ({ children }) => {
  const { loading, data, refetch } = useQuery(GET_JOBS);

  const jobs = data?.getJobs?.jobs;

  const getCtx = useCallback(() => {
    return {
      loading,
      jobs,
      refetch
    };
  }, [jobs, loading, refetch]);

  return <JobsContext.Provider value={getCtx()}>{children}</JobsContext.Provider>;
};

export const useJobs = () => {
  return useContext(JobsContext);
};

export default JobsContext.Consumer;
