import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Styles } from 'styles';

const JobCardWrapper = styled(Link)`
  display: grid;
  grid-template-columns: 40% 30% 30%;
  grid-gap: 12px;
  text-decoration: none;
  color: ${Styles.color.white};
  border-radius: ${Styles.borderRadius};
  padding: 12px;
  margin-top: 8px;
  transition: 0.2s;
  background-color: ${Styles.color.white}11;

  :hover {
    background-color: ${Styles.color.white}19;
  }
`;

const ImageAndTitleWrapper = styled.div`
  display: flex;
`;

const SHARED_STYLES = css`
  height: 60px;
  width: 60px;
  border-radius: ${Styles.borderRadius};
  border: 1px solid ${Styles.color.white}44;
  margin-right: 12px;
`;

const Img = styled.img`
  ${SHARED_STYLES}
`;

const NoImgWrapper = styled.div`
  ${SHARED_STYLES}
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0.5;
`;

const Title = styled.h4`
  margin: 0 0 8px;
  font-size: 20px;
`;

const Company = styled.div``;

const Location = styled.div``;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.div<{ isActive?: boolean }>`
  margin: 0 4px;
  padding: 2px;
  border: 2px solid ${Styles.color.white}44;
  border-radius: ${Styles.borderRadius};
  height: min-content;
  font-size: 12px;
  white-space: nowrap;

  :first-of-type {
    margin-left: 0;
  }

  ${(p) =>
    p.isActive &&
    css`
      color: ${Styles.color.primary};
      border-color: ${Styles.color.primary};
    `}
`;

export const JobCard = ({ position, tags, company, location, id, logoUrl, activeTags }) => (
  <JobCardWrapper to={'/jobs/' + id}>
    <ImageAndTitleWrapper>
      {logoUrl ? <Img src={logoUrl} /> : <NoImgWrapper>{company[0]?.toUpperCase()}</NoImgWrapper>}

      <div>
        <Title>{position}</Title>
        <Company>{company}</Company>
      </div>
    </ImageAndTitleWrapper>

    <Location>📍 {location}</Location>

    <Tags>
      {tags.map(
        (tag) =>
          tag && (
            <Tag key={tags} isActive={activeTags.includes(tag)}>
              {tag}
            </Tag>
          )
      )}
    </Tags>
  </JobCardWrapper>
);
