import { AppLayout } from 'components/app-layout';
import { AppRouter } from 'components/app-router';
import { ReduceProviders } from 'hooks/reduce-providers';
import { getProviders } from 'hooks/reduce-providers/providers';
import { GlobalStyles } from 'styles/global-styles';

import { Provider, chain, createClient, defaultChains } from 'wagmi';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

// API key for Ethereum node
const infuraId = process.env.REACT_APP_INFURA_PROJECT_ID;

const chains = defaultChains;
const defaultChain = chain.mainnet;

// Set up connectors
const client = createClient({
  autoConnect: true,
  connectors({ chainId }) {
    const chain = chains.find((x) => x.id === chainId) ?? defaultChain;
    const rpcUrl = chain.rpcUrls.infura
      ? `${chain.rpcUrls.infura}/${infuraId}`
      : chain.rpcUrls.default;
    return [
      new InjectedConnector(),
      new CoinbaseWalletConnector({
        options: {
          appName: 'Web3 developer tools',
          chainId: chain.id,
          jsonRpcUrl: rpcUrl
        }
      }),
      new WalletConnectConnector({
        options: {
          qrcode: true,
          rpc: {
            [chain.id]: rpcUrl
          }
        }
      })
    ];
  }
});

function App() {
  return (
    <Provider client={client}>
      <ReduceProviders providers={getProviders()}>
        <GlobalStyles />

        <AppLayout>
          <AppRouter />
        </AppLayout>
      </ReduceProviders>
    </Provider>
  );
}

export default App;
